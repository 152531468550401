import React from "react";
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../../components/Layout";

const TagsPage = ({ data }) => {
  return (
    <Layout>
      <Helmet
        title={data.allContentfulTags.edges[0].node.metaTitle}
        desc={data.allContentfulTags.edges[0].node.metaDescription}
      />
      <section className="hero is-primary is-bold is-medium">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">
                  <h1 className="title">Tags</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container content">
          <div className="columns">
            <div
              className="column is-10 is-offset-1"
              style={{ marginBottom: "6rem" }}
            >
              <ul className="taglist">
                {data.allContentfulTag.group.map((tag) => (
                  <li key={JSON.stringify(tag.nodes.id)}>
                    <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                      {tag.fieldValue} ({tag.totalCount})
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    allContentfulTags {
      edges {
        node {
          id
          metaDescription
          metaTitle
          title
        }
      }
    }
    allContentfulTag {
      group(field: name) {
        nodes {
          id
          name
        }
        fieldValue
        totalCount
      }
    }
  }
`;

/*export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`; */
